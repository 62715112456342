@import 'variables';
@import 'custom_bootstrap';

.header {
  border-bottom: solid 5px $blue;
  padding: 0;
  background: $white;
  .bg-light {
    background-color: $white !important;
    justify-content: space-between;
    align-items: center;
  }

  .centivo-logo {
    height: 25px;
    margin: 0;

    a {
      display: block;
      height: inherit;
    }

    img {
      height: inherit;
      width: auto;
      display: block;
    }
  }

  .navbar {
    padding: 1rem 0;
    .navbar-nav {
      align-items: center;
      li {
        text-transform: uppercase;
        border-radius: 25px;
        border: solid 2px $white;
        margin-right: 10px;
        padding: 0 10px;
        &.active,
        &:hover {
          border: solid 2px $color-primary-pink;
          background: $color-primary-pink;
          position: relative;
          .nav-link,
          .nav-link:hover {
            color: $white;
          }
        }
        &:last-child:hover {
          border-color: $white;
        }
        &.logout {
          padding: 0.5rem 0rem;
          margin: 0;
          background: none;
          cursor: pointer;

          .icon {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    .centivo-logo {
      height: 25px;
      margin: 0;
    }

    .navbar-light {
      .navbar {
        justify-content: flex-end;
      }
      .navbar-nav {
        padding-top: 1px;
        li {
          border-radius: 0px;
          border-width: 0 0 1px 0 !important;
          border-color: $input-border;
          margin: 0;
          &.active,
          &:hover {
            border-width: 0 0 1px 0 !important;
            &:before {
              display: none;
            }
          }
          &.logout {
            padding: 0 10px;
            margin: 0;
          }
          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}
