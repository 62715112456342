.skeleton-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: rgb(246, 247, 250);
  margin: 0px 0px 10px 0px;
  border-radius: 5px;
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2s infinite;
  .shimmer {
    width: 100%;
    height: 100%;
    // border: 1px solid black;
    background: radial-gradient(circle, rgba(2, 0, 36, 0.05) 0%, rgba(0, 0, 0, 0.05) 47%, rgba(255, 255, 255, 0.05) 100%);
    // -webkit-filter: blur(5px);
    // -moz-filter: blur(5px);
    // -o-filter: blur(5px);
    // -ms-filter: blur(5px);
    // filter: blur(5px);
    // box-shadow: 0px 100px 100px 100px rgba(179, 179, 179, 0.5);
    opacity: 1;
    //  transform: skewX(-20deg);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  // 50% {
  //   transform: translateX(0%);
  // }
  100% {
    transform: translateX(100%);
  }
}
