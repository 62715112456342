.isDragActive {
    .form-group {
        position: relative;
        textarea {
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: transparent;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: transparent;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: transparent;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: transparent;
              }

        }
        // textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        //     color: transparent;
        //   }
        .isDragActiveOverlay {
            display: block;
            background-color: #EBEBEB;
            position: absolute;
            left: 50%;
            top: 55%;
            width: 96%;
            height: 80%;
            transform: translate(-50%, -50%);
            display: flex;

            flex-direction: column;
            align-items: center;
            justify-content: center;
           // border:2px transparent transparent;
            border-radius: 15px;
            div {
               // flex: 1 1 100%;
                color: #7A7E81
            }
            .title {
                line-height: 32px;
                font-size: 26px;
            }
            .subtitle {
                line-height: 16px;
                font-size: 12px;
            }
        }
    }
}

.file-size-error {
  background-color: #D62020;
  width: 460px;
  height: 40px;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 18px;
  line-height: 22px;
 // padding-left: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  div {
    padding-left: 16px;
    svg {
      cursor: pointer;
    }
  }
}